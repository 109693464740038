@import "settings/vars";
@import "tools/mixin";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$transition-time: .25s;
$max-width: 300px;
$icon-size: 20px;
$border-radius: 20px;
$max-container-width: min(95vw, 1650px);

body.compare-open {
    overflow: hidden;
}

#specs-block-root {
    .wrapper {
        max-width: $max-container-width;
    }

    .btn {
        cursor: pointer;

        &:disabled {
            cursor: default;
            opacity: .5;
            pointer-events: none;
        }
    }

    .block {
        padding: 50px 0 10px;
        color: $color-white;
        background-color: $color-black;
        background-position: left bottom;
        background-repeat: no-repeat;

        .is-h2 {
            color: $color-white;
            margin: 0;
        }

        .btn-compare {
            margin-top: 2rem;
            margin-left: .5rem;
            font-weight: bold;
            text-align: center;
            color: $color-white;
            padding-left: 24px;
            position: relative;
            display: flex;
            align-items: center;
            transition: all $transition-time;

            &::before {
                position: absolute;
                content: "";
                border: 2px solid $color-white;
                width: 12px;
                height: 12px;
                left: 4px;
                transition: all $transition-time;
            }

            &.del {
                &::before {
                    background-color: $color-white;
                }
            }

            &:disabled {
                cursor: default;
            }

            &:hover:not(:disabled) {
                color: $color-red-dark;

                &::before {
                    border-color: $color-red-dark;
                }

                &.del {
                    &::before {
                        background-color: $color-red-dark;
                    }
                }
            }
        }
    }

    .specs-table {
        display: flex;
        margin: 0;

        .list {
            li:not(:first-child) {
                border-bottom: 1px solid $color-gray-light;
            }
        }

        &-header {
            display: flex;
            align-items: center;
            margin: 2rem 0;

            .btn {
                @include icon("arrowthin", $color-white, $icon-size, $icon-size);
                padding: 0;
                width: calc($icon-size * 2);
                height: calc($icon-size * 2);
                background-color: $color-gray;
                border: none;
                flex-shrink: 0;

                &:hover:not(:disabled) {
                    background-color: $color-red;
                }

                &-prev {
                    transform: rotate(90deg);
                }

                &-next {
                    transform: rotate(-90deg);
                }
            }

            .titles {
                display: flex;
                flex-grow: 1;
                margin: 0 1rem;
                overflow-x: auto;
                scrollbar-width: none;

                .item {
                    flex-grow: 1;
                    text-align: center;
                    color: $color-gray;
                    font-weight: bold;
                    border-bottom: 3px solid $color-gray;
                    transition: opacity $transition-time;
                    opacity: .75;
                    cursor: pointer;
                    white-space: nowrap;
                    padding: 0 1rem;

                    &.active {
                        opacity: 1;
                        color: $color-white;
                        border-color: $color-red;
                    }
                }
            }
        }

        &-labels,
        &-values {
            .title {
                font-weight: bold;
                background-color: $color-red;
            }

            li {
                padding: .5rem 1rem .5rem 0;
            }
        }

        &-labels {
            width: 45%;
            padding-left: $border-radius;
            font-weight: bold;

            .title {
                background-color: transparent;
            }
        }

        &-values {
            width: 55%;
            display: flex;
            overflow-x: auto;
            overscroll-behavior-x: none;
            scroll-behavior: smooth;
            padding-right: $border-radius;
            padding-left: $border-radius;

            .specs-table-item {
                flex-grow: 1;
                min-width: 85%;

                &:first-child {
                    .title {
                        margin-left: -$border-radius;
                        padding-left: $border-radius;
                        border-top-left-radius: $border-radius;
                        border-bottom-left-radius: $border-radius;
                    }
                }

                &:last-child {
                    .title {
                        margin-right: calc($border-radius * -1);
                        border-top-right-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                }
            }
        }
    }

    .compare-popup {
        position: fixed;
        left: 1rem;
        bottom: 0;
        width: $max-width;
        color: $color-white;
        background-color: $color-red;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        transition: bottom $transition-time;
        z-index: 1050;

        &.empty {
            bottom: -3rem;
        }

        &-header {
            cursor: pointer;
            padding: .5rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .popup-title {
                font-weight: bold;
            }

            .expand-icon {
                @include icon("arrowthin", $color-white, $icon-size, $icon-size);
                transition: transform $transition-time;
                transform: rotate(180deg);
                width: 20px;
                height: 20px;
            }

            .products-icon {
                background: $color-white;
                border-radius: 50%;
                color: $color-black;
                width: 30px;
                height: 30px;
                text-align: center;
                font-weight: bold;
            }

            .close-icon {
                @include icon("close2", $color-white, $icon-size, $icon-size);
                width: 20px;
                height: 20px;
                transition: margin-right $transition-time, opacity $transition-time;
            }
        }

        &-body {
            height: 0;
            padding: 0 1rem;
            transition: height $transition-time;
            display: flex;
            flex-direction: column;
            position: relative;

            .products {
                padding: 1rem 0;

                .product-row {
                    padding: .5rem 0;
                    display: flex;

                    .remove-icon {
                        @include icon("close2", $color-white, 20px, 20px);
                        width: $icon-size;
                        height: $icon-size;
                        margin-left: auto;
                        cursor: pointer;
                    }

                    &:not(:first-child) {
                        border-top: 1px solid $color-white;
                    }

                    &-title {
                        font-weight: bold;
                    }
                }
            }

            .btn {
                display: block;
                width: calc(100% - 2rem);
                border: 1px solid $color-white;
                position: absolute;
                inset: auto 1rem 1rem;
                visibility: hidden;
                opacity: 0;
                transition: all calc($transition-time / 2);

                &:disabled {
                    opacity: .75 !important;
                    cursor: default;

                    &:hover {
                        background: inherit;
                    }
                }
            }
        }

        &.expanded {
            .compare-popup-header {
                cursor: pointer;

                .expand-icon {
                    transform: rotate(0);
                }

                .close-icon {
                    margin-right: -60px;
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }
            }

            .compare-popup-body {
                .btn {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    .compare-modal-wrapper {
        position: fixed;
        inset: 0;
        z-index: -1;
        overflow-y: auto;

        .compare-modal-backdrop {
            position: fixed;
            inset: 0;
            opacity: 0;
            transition: opacity $transition-time;
            background-color: rgba($color-black, .25);
            backdrop-filter: blur(.25rem);
        }

        .compare-modal {
            position: relative;
            opacity: 0;
            transition: opacity calc($transition-time * 2);
            margin: 5rem auto;
            width: 100%;
            min-height: 10rem;
            max-width: $max-container-width;
            background-color: $color-white;
            border-radius: $border-radius;

            .close {
                @include icon("close", $color-white, calc($icon-size / 1.25), calc($icon-size / 1.25));
                background-position: center right;
                position: absolute;
                top: -2.5rem;
                right: 1rem;
                padding-right: $icon-size;
                color: $color-white;
                font-weight: bold;

                &:hover {
                    @include icon("close", $color-red, calc($icon-size / 1.25), calc($icon-size / 1.25));
                    background-position: center right;
                    color: $color-red;
                }
            }

            &-header {
                padding: 1rem 3rem;

                .custom-control {
                    &-input {
                        display: none;
                    }

                    &-label {
                        display: flex;
                        align-items: center;
                        margin: 0;
                        position: relative;
                        cursor: pointer;
                        padding-left: calc($icon-size * 2 + 10px);

                        &::before,
                        &::after {
                            position: absolute;
                            content: "";
                            transition: background-color $transition-time, left $transition-time;
                        }

                        &::before {
                            left: 0;
                            width: calc($icon-size * 2);
                            height: calc($icon-size / 1.4);
                            border-radius: calc($border-radius / 2);
                            background-color: rgba($color-gray, .5);
                        }

                        &::after {
                            left: -2px;
                            width: $icon-size;
                            height: $icon-size;
                            border-radius: 50%;
                            background-color: $color-white;
                            box-shadow: 0 0 2px rgba($color-black, .75);
                        }
                    }

                    .custom-control-input:checked ~ .custom-control-label {
                        &::before {
                            background-color: rgba($color-red, .75);
                        }

                        &::after {
                            left: calc($icon-size + 2px);
                        }
                    }
                }
            }

            &-body {
                padding: 1rem 2rem;
            }

            &-footer {
                gap: 1rem;
                padding-bottom: 2rem;

                // noinspection Stylelint
                .btn--social-share {
                    color: $color-black;
                    border: 2px solid $color-black;
                    padding-left: 40px;
                    padding-right: 18px;
                    transition: all $transition-time;

                    &::after {
                        @include icon("share", $color-black, $icon-size, $icon-size);
                        transition: all $transition-time;
                        left: .75rem;
                    }

                    &:hover {
                        color: $color-white;
                        background-color: $color-red-dark;
                        border-color: $color-red-dark;

                        &::after {
                            @include icon("share", $color-white, $icon-size, $icon-size);
                        }
                    }
                }
            }
        }

        .specs-table {
            &-header {
                .titles {
                    .item {
                        color: $color-black;

                        &.active {
                            color: inherit;
                            border-color: $color-red;
                        }
                    }
                }
            }

            &-labels,
            &-values {
                .title {
                    color: $color-white;
                }
            }
        }

        &.visible {
            z-index: 20001;

            .compare-modal-backdrop,
            .compare-modal {
                opacity: 1;
            }
        }
    }

    @include media-breakpoint-up(md) {
        .specs-table {
            &-labels {
                width: 33.3334%;
            }

            &-values {
                width: 66.7776%;

                .specs-table-item {
                    min-width: 50%;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .specs-table {
            &-labels {
                width: 25%;
            }

            &-values {
                width: 75%;

                .specs-table-item {
                    min-width: 33.3333%;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .specs-table {
            &-labels {
                width: 20%;
            }

            &-values {
                width: 80%;

                .specs-table-item {
                    min-width: 25%;
                }
            }
        }
    }
}
